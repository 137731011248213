<template>
  <div class="container">
    <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
      <van-col v-for="(item,index) in list" :key="index" span="24">
        <div class="list-item van-hairline--bottom" @click="toDetail(item)">
          <div class="list-item-hd">
            <div class="list-item-title">{{ item.book_weeks }}</div>
            <div class="list-item-txt">{{ item.class_date | formatEnd }}</div>
            <div class="list-item-txt">{{ item.class_start }}</div>
          </div>
          <div class="list-item-bd">
            <div class="list-item-title">{{ item.course_name }}</div>
            <div class="list-item-txt" style="text-overflow:ellipsis">{{ item.course_studio.studio_name }}</div>
            <div class="list-item-txt">
              <van-icon style="margin:0 5px" class-prefix="icon" size="12" name="HomeOutline" />
              <span style="margin-right:10px">{{ item.room_name }} </span>
              <van-icon style="margin-right:5px" class-prefix="icon" size="12" name="PersonalOutline" />
              {{ item.course_teacher.teacher_name }}
            </div>
          </div>
          <div class="list-item-ft">
            <van-button type="primary" size="mini" plain>去请假</van-button>
          </div>
        </div>
      </van-col>
    </van-list>
  </div>
</template>
<script>
import PageMixin from '@/mixins/page'
import { Cell, CellGroup, DatetimePicker, Picker, Calendar, Checkbox, CheckboxGroup, Field, Tag } from 'vant'
export default {
  name: 'StudentLeave',
  filters: {
    formatEnd(value) {
      return value && value.slice(-5)
    }
  },
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {
  },
  components: {
      [Tag.name]:Tag,
      [Field.name]:Field,
      [Cell.name]:Cell,
      [Picker.name]:Picker,
      [Checkbox.name]:Checkbox,
      [CheckboxGroup.name]:CheckboxGroup,
      [Calendar.name]:Calendar,
      [CellGroup.name]:CellGroup,
      [DatetimePicker.name]:DatetimePicker,
  },
  methods: {
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.schedule_checklist({ 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    },
    toDetail(item){
      this.$router.push({path:'/student/ask-leave?book_id=',query:{book_id:item.book_id}})
    },
  }
}
</script>
<style lang="less" scoped>
  .list{
    &-item{
      background: #fff;
      padding: 10px 15px;
      display: flex;
      justify-content: flex-start;
      &-hd{
        width: 50px;
      }
      &-bd{
        flex: 1;
      }
      &-status{
          font-size: 12px;
      }
      &-title{
        font-size: 14px;
        font-weight: bold;
        color: #333;
        margin-bottom: 5px;
      }
      &-txt{
        line-height: 1.8;
        font-size: 12px;
        color: #666;
        &-flex{
          display: flex;
          align-items: center;
        }
        &-room{
          display: inline-block;
          max-width: 120px;
          overflow: hidden;
        }
        &-teacher{
          display: inline-block;
          width: 60px;
          overflow: hidden;
        }
      }
      &-ft{
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
</style>



